import React from "react";
import styled from "styled-components";
import CarouselContext from "../../context/Carousel";

const Scroll = styled.div`
  overflow: auto;
  overflow-y: hidden;
  margin: 40px auto 0;
  white-space: nowrap;
  -ms-overflow-style: none;
  cursor: grab;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`;

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
  margin-left: calc(((100vw - 1200px) / 2));
  @media (max-width: 1200px) {
    margin-left: 20px;
  }
  @media (max-width: 1024px) {
    margin-left: calc(((100vw - 880px) / 2));
  }
  @media (max-width: 900px) {
    margin-left: calc(((100vw - 768px) / 2));
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-left: auto;
  }
`;

const Content = styled.div`
  max-width: 100%;
  @media (max-width: 768px) {
    margin-left: 20px;
  }
`;

export const Scrollable: React.FC = ({ children }) => {
  const [isClickActive, setClickActive] = React.useState(false);
  const [startX, setStartX] = React.useState(0);
  const [scrollLeft, setScrollLeft] = React.useState(0);
  const [offset, setOffset] = React.useState(0);
  const ref = React.createRef<HTMLDivElement>();

  const onMouseDownHandler = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (ref?.current?.offsetLeft !== undefined) {
      setClickActive(true);
      setStartX(e.pageX - ref?.current?.offsetLeft);
      setScrollLeft(ref?.current?.scrollLeft);
    }
  };
  const onMouseUpHandler = () => {
    setClickActive(false);
  };
  const onMouseMoveHandler = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (isClickActive && ref?.current?.offsetLeft !== undefined) {
      const x = e.pageX - ref?.current?.offsetLeft;
      const walk = (x - startX) * 2;
      ref.current.scrollLeft = scrollLeft - walk;
      setOffset(ref.current.scrollLeft);
    }
  };

  return (
    <Wrapper>
      <Content>
        <Scroll
          ref={ref}
          onMouseDown={onMouseDownHandler}
          onMouseUp={onMouseUpHandler}
          onMouseMove={onMouseMoveHandler}
        >
          <CarouselContext.Provider value={{ offset: offset }}>
            {children}
          </CarouselContext.Provider>
        </Scroll>
      </Content>
    </Wrapper>
  );
};
