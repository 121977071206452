import React from "react";
import Service from "./Service";
import { SubTitle, Title } from "../Typography";
import Container from "../Container";
import Section from "../Section";
import { useIntl } from "gatsby-plugin-intl";
import { Scrollable } from "../Scrollable/Scrollable";
import { ServicesProps } from "../../types/Service";
import { CodeColors } from "../../types/CodeColors";
import { SectionPros } from "../../types/SectionsProps";

const ServicesSection: React.FC<ServicesProps & SectionPros> = ({
  services,
  title,
  subtitle,
}) => {
  const intl = useIntl();

  return (
    <Section id="services" background={CodeColors.white}>
      <Container>
        <Title color={CodeColors.cyan}> {title} </Title>
        <SubTitle> {subtitle} </SubTitle>
      </Container>
      <Scrollable>
        {services &&
          services.map((service) => (
            <Service
              key={service.key}
              image={service.image}
              name={service.name}
              description={service.description}
            />
          ))}
      </Scrollable>
    </Section>
  );
};

export default ServicesSection;
