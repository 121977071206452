import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: -1px;
  right: 0;
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const CutElement: React.FC = () => {
  return (
    <Wrapper>
      <svg
        width="153"
        height="570"
        viewBox="0 0 153 570"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0H153L0 570V0Z" fill="#16435F" />
      </svg>
    </Wrapper>
  );
};
