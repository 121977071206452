import React from "react";
import styled from "styled-components";
import FadeElement from "../Scrollable/FadeElement";
import { CodeColors } from "../../types/CodeColors";

const Wrapper = styled.div`
  background: ${CodeColors.darkBlue};
  border-radius: 20px;
  max-width: 400px;
  opacity: ${(props) => (props.hidden ? "0" : "1")};
  padding: 25px;
  text-align: center;
  display: inline-block;
  white-space: normal;
  margin-right: 20px;
  @media (max-width: 768px) {
    max-width: 260px;
  }
  -webkit-touch-callout: none;
  -webkit-user-select: none;   
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;  
}
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const ServiceImage = styled.img`
  height: 150px;
`;

const ServiceName = styled.h5`
  font-size: 28px;
  line-height: 32px;
  color: ${CodeColors.cyan};
  margin: 5px 0;
  @media (max-width: 768px) {
    height: 60px;
  }
`;

const ServiceDescription = styled.p`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  height: 150px;
  text-transform: lowercase;
  color: ${CodeColors.white};
  margin: 5px 0;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

type ServiceProps = {
  image?: string;
  name?: string;
  description?: string;
};

const Service: React.FC<ServiceProps> = ({ image, name, description }) => {
  return (
    <FadeElement>
      <Wrapper>
        <Content>
          <ServiceImage alt={name} src={image} />
          <ServiceName>{name}</ServiceName>
          <ServiceDescription>{description}</ServiceDescription>
        </Content>
      </Wrapper>
    </FadeElement>
  );
};

export default Service;
