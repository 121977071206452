import * as React from "react";
import { CodeColors } from "../../types/CodeColors";

type PinPros = {
  color: CodeColors;
};

const Pin: React.FC<PinPros> = ({ color }) => {
  return (
    <svg
      width={19}
      height={30}
      viewBox="0 0 19 30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.008 0A9.002 9.002 0 00.26 11.16c.231 1.449.579 2.637.984 3.507.898 1.855 4.606 8 6.315 10.782-2.318.29-3.39 1.305-3.39 2.232C4.17 28.783 5.678 30 9.009 30c3.33 0 4.837-1.188 4.837-2.319 0-.927-1.072-1.942-3.39-2.232 1.71-2.782 5.417-8.927 6.315-10.782.405-.87.753-2.03.985-3.508.174-.695.26-1.42.26-2.145C17.986 4.03 13.96 0 9.008 0zm3.417 27.652c-.086.261-1.245.928-3.446.928s-3.36-.667-3.447-.928c.058-.232 1.014-.811 2.81-.898.029.029.029.058.057.087a.668.668 0 00.58.318.668.668 0 00.579-.318s.029-.03.058-.087c1.796.116 2.723.666 2.81.898z"
        fill={color}
      />
    </svg>
  );
};

export default Pin;
