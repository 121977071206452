import React, { useEffect } from "react";
import styled from "styled-components";
import { useMenu } from "../../context/Menu";
import { useScroll } from "../../context/Scroll";
import { CodeColors } from "../../types/CodeColors";

type SectionProps = {
  id?: string;
  background: CodeColors;
  fullHeight?: boolean;
  height?: string;
};

const Wrapper = styled.section<SectionProps>`
  min-height: ${(props) => (props.fullHeight ? "calc(100vh - 75px)" : "100%")};
  height: ${(props) => (props.height ? props.height : "100%")};
  background: ${(props) => props.background};
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin: auto;
`;
const Anchor = styled.div`
  padding-top: 75px;
  margin-top: -75px;
`;

const Section: React.FC<SectionProps> = ({
  id,
  background,
  fullHeight = true,
  height,
  children,
}) => {
  const ref = React.createRef<HTMLElement>();
  const { activeItem, setActiveItem } = useMenu();
  const { currentPosition, scrolling } = useScroll();

  const getMinValue = () => {
    return (ref?.current?.offsetTop || 0) - 90;
  };

  const getMaxValue = () => {
    return getMinValue() + getOffsetTopPosition();
  };

  const getOffsetTopPosition = () => {
    return (ref?.current?.offsetHeight || 0) - 90;
  };

  const isActive = () => {
    return activeItem === `#${id}`;
  };

  const isCurrent = () => {
    return currentPosition > getMinValue() && currentPosition < getMaxValue();
  };

  useEffect(() => {
    !isActive() && isCurrent() && setActiveItem(`#${id}`);
  }, [currentPosition]);

  useEffect(() => {
    !scrolling && isCurrent() && history.pushState({}, "", `#${id}`);
  }, [scrolling]);

  return (
    <React.Fragment>
      <Anchor id={id} />
      <Wrapper
        ref={ref}
        height={height}
        background={background}
        fullHeight={fullHeight}
      >
        {children}
      </Wrapper>
    </React.Fragment>
  );
};

export default Section;
