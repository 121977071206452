import React from "react";
import styled from "styled-components";
import { CodeColors } from "../../types/CodeColors";

type ImageWrapperProps = {
  background?: string;
};

type CardProps = {
  image?: string;
};

const Wrapper = styled.div`
  max-width: 300px;
  display: inline-block;
  white-space: normal;
  margin-right: 20px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const ImageWrapper = styled.div<ImageWrapperProps>`
  border-radius: 20px 20px 0 0;
  height: 275px;
  width: 100%;
  background-image: url("${(props) =>
    props.background ? props.background : ""}");
  background-position: top;
  background-repeat: no-repeat;
`;

const BodyWrapper = styled.div<ImageWrapperProps>`
  border-radius: 0 0 20px 20px;
  height: 100%;
  width: 100%;
  background: ${CodeColors.white};
`;

const Card: React.FC<CardProps> = ({ image, children }) => {
  return (
    <Wrapper>
      <ImageWrapper background={image} />
      <BodyWrapper>{children}</BodyWrapper>
    </Wrapper>
  );
};

export default Card;
