import React from "react";
import styled, { keyframes } from "styled-components";
import { TagProps } from "../../types/TagProps";

const moveTag = keyframes`
    0% {          
      transform: translateX(32px);
      opacity:0;
    }
    100% {      
      transform: translateX(0);
      opacity:1;
    }
  `;

const hoverTag = keyframes`    
    100% {      
      transform: translateX(5px);      
    }
  `;

const resetTag = keyframes`    
    0% {      
      transform: translateX(5px);
    }
    100% {
      opacity:1;
      transform: translateX(0px);      
    }
  `;

const Wrapper = styled.div<WrapperProps>`
  position: absolute;
  opacity: ${(props) => (props.isMouseHover ? "0" : "1")};
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  left: ${(props) => props.left};
  bottom: ${(props) => props.bottom};
  cursor: pointer;
  animation: ${(props) => {
      if (!props.visible) {
        return;
      }

      return props.isMouseHover ? moveTag : resetTag;
    }}
    ${(props) => (props.isMouseHover ? "2s" : "0.5s")} forwards;
  animation-delay: ${(props) =>
    props.isMouseHover ? `${props.index * 0.3}s` : "0s"};
  &:hover {
    path {
      fill: #dc2870;
    }
    animation: ${hoverTag} 0.5s forwards;
  }
`;

type WrapperProps = {
  isMouseHover?: boolean;
  right?: string;
  top?: string;
  left?: string;
  bottom?: string;
  visible: boolean;
  index: number;
};

const Tag: React.FC<TagProps & WrapperProps> = ({
  active,
  visible,
  label,
  right,
  top,
  left,
  bottom,
  slide,
  index,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  const [isHover, setIsHover] = React.useState(true);
  React.useEffect(() => {
    if (!visible) {
      setIsHover(true);
    }
  }, [visible]);

  return (
    <Wrapper
      onMouseOver={() => {
        setIsHover(false);
        onMouseEnter && onMouseEnter();
      }}
      onMouseOut={() => {
        onMouseLeave && onMouseLeave();
      }}
      right={right}
      top={top}
      left={left}
      bottom={bottom}
      onClick={() => onClick && onClick(slide)}
      visible={visible}
      index={index}
      isMouseHover={isHover}
    >
      <svg
        width={212}
        height={40}
        viewBox="0 0 212 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.52 3.73A5 5 0 0113.358 0h193.161c3.277 0 5.668 3.1 4.836 6.27l-7.875 30a5 5 0 01-4.836 3.73H5.482C2.205 40-.186 36.9.646 33.73l7.875-30z"
          fill={active ? "#DC2870" : "#00769F"}
          pointerEvents="none"
        />
        <text x="50%" fontSize="20px" fill="#FFF" y="65%" textAnchor="middle">
          <tspan fontWeight="900">{label && label.slice(0, 1)}</tspan>
          <tspan fontWeight="200">{label && label.slice(1)}</tspan>
        </text>
      </svg>
    </Wrapper>
  );
};

export default Tag;
