import React, { useEffect } from "react";
import styled from "styled-components";
import Container from "../Container";
import { WorldMap } from "./WorldMap";
import { MapLegend } from "./Legend";
import { SubTitle, Title } from "../Typography";
import Section from "../Section";
import Card from "./WorldCard";
import { useIntl } from "gatsby-plugin-intl";
import Cut from "../../images/map/cut.svg";
import ToolTip from "../ToolTip";
import { CodeColors } from "../../types/CodeColors";
import { CoordinateCard } from "../../types/CoordinateCard";
import { useScroll } from "../../context/Scroll";
import { SectionPros } from "../../types/SectionsProps";
import { PinMapProps, PinMap } from "../../types/PinMap";

export enum countriesColors {
  comingSoon = "#CCC",
  active = "#58DEDE",
  selected = "#DC2870",
}

const Content = styled.div`
  display: flex;
  padding-top: 50px;
  position: relative;
  @media (max-width: 768px) {
    padding-bottom: 200px;
  }
`;

const CutWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: url("${Cut}");
  background-repeat: no-repeat;
  width: 150px;
  display: none;
  @media (min-width: 1400px) {
    display: block;
  }
`;

const LegendWrapper = styled.div`
  flex: 1;
`;

const MapWrapper = styled.div`
  flex: 4;
`;

const MapSection: React.FC<SectionPros & PinMapProps> = ({
  title,
  subtitle,
  mapPin,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean | null>(false);
  const [tooltipOpen, setTooltipOpen] = React.useState<boolean | null>(false);
  const [tooltipCoords, setTooltipCoords] = React.useState<number[]>([0, 0]);
  const [cardCoords, setCardCoords] = React.useState<number[]>([0, 0]);
  const [selectedCountryKey, setSelectedCountryKey] = React.useState<
    string | null
  >(null);
  const [tooltipText, setTooltipText] = React.useState<string>("");
  const { scrolling } = useScroll();
  useEffect(() => {
    if (scrolling) {
      setTooltipOpen(false);
    }
  }, [scrolling]);

  const intl = useIntl();
  const [selectedCountryKey2, setSelectedCountryKey2] = React.useState<
    string | null
  >(null);
  let countries: PinMap[] = [];

  if (mapPin !== undefined) {
    countries = mapPin;
  }

  const openHandler = (countryKey: string, coordinateCard: CoordinateCard) => {
    setCardCoords([coordinateCard.top, coordinateCard.left]);
    setSelectedCountryKey(countryKey);
    setIsOpen(true);
  };

  const toolTipHandler = (
    e:
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | React.MouseEvent<SVGGElement, MouseEvent>,
    text?: string
  ) => {
    const { top, left, width } = e.currentTarget.getBoundingClientRect();
    setTooltipCoords([top, left + width]);

    if (text) {
      setTooltipOpen(true);
      setTooltipText(text);
    } else {
      setTooltipOpen(false);
    }
  };

  const closeHandler = () => {
    setSelectedCountryKey(null);
    setIsOpen(false);
  };

  const toggleColor = (country: PinMap) => {
    if (!country.active) {
      return CodeColors.gray;
    }

    return selectedCountryKey == country.key
      ? CodeColors.magenta
      : CodeColors.lightBlue;
  };

  const selectedCountry = countries.find(
    (country) => country.key === selectedCountryKey
  );

  const selectNation = (key: string) => {
    setSelectedCountryKey2(key);
  };

  return (
    <Section id="where-we-are" background={CodeColors.blue}>
      <CutWrapper />
      <Container>
        <Title color={CodeColors.white}> {title} </Title>
        <SubTitle color={CodeColors.white}> {subtitle} </SubTitle>
        <Content>
          {isOpen === true ? (
            <Card
              coords={cardCoords}
              selectedCountry={selectedCountry}
              close={closeHandler}
            />
          ) : null}
          {tooltipOpen && (
            <ToolTip coords={tooltipCoords} tooltipText={tooltipText} />
          )}
          <LegendWrapper>
            <MapLegend
              toggleTooltip={toolTipHandler}
              openCard={openHandler}
              countries={countries}
              toggleColor={toggleColor}
              handleSelection={selectNation}
            />
          </LegendWrapper>
          <MapWrapper>
            <WorldMap
              toggleTooltip={toolTipHandler}
              openCard={openHandler}
              countries={countries}
              toggleColor={toggleColor}
              selectedCountryKey2={selectedCountryKey2}
            />
          </MapWrapper>
        </Content>
      </Container>
    </Section>
  );
};

export default MapSection;
