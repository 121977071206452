import React from "react";
import styled from "styled-components";

type TooltipProps = {
  coords: number[];
  tooltipText?: string;
};

const Wrapper = styled.div<{ top: number; left: number }>`
  position: fixed;
  top: ${(props) => props.top - 6}px;
  left: ${(props) => props.left + 10}px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  min-height: 48px;
  min-width: 121px;
  text-align: center;
  border-radius: 10px;
  color: white;
  background: #dc2870;
  z-index: 100;
  white-space: nowrap;
  &:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 8px solid #dc2870;
    left: -8px;
    top: 18px;
  }
`;

const TooltipText = styled.div`
  margin-top: 15px;
`;

const ToolTip: React.FC<TooltipProps> = ({ coords, tooltipText }) => {
  return (
    <Wrapper top={coords && coords[0]} left={coords && coords[1]}>
      <TooltipText>{tooltipText}</TooltipText>
    </Wrapper>
  );
};

export default ToolTip;
