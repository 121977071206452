import React from "react";

type ICarouselContext = {
  offset: number;
};

const CarouselContext = React.createContext<ICarouselContext>({
  offset: 0,
});

export default CarouselContext;

export const useCarousel = (): ICarouselContext =>
  React.useContext(CarouselContext);
