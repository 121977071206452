import React from "react";
import styled from "styled-components";

type NavigationProps = {
  size: number;
  onClick: (index: number) => void;
  activeItems: number;
};

type NavigationItemProps = {
  isActive: boolean;
};

const Wrapper = styled.ul`
  position: absolute;
  bottom: 40px;
  right: 50%;
  list-style: none;
  width: 600px;
  text-align: center;
  padding: 0;
  margin: 0;
  @media (max-width: 1024px) {
    width: 450px;
  }
  @media (max-width: 768px) {
    width: 100%;
    right: auto;
    left: 20px;
    text-align: left;
  }
`;
const Item = styled.li<NavigationItemProps>`
  width: 35px;
  height: 3px;
  background: rgba(204, 204, 204, ${(props) => (props.isActive ? "1" : "0.5")});
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
`;

export const Navigation: React.FC<NavigationProps> = ({
  size,
  onClick,
  activeItems,
}) => {
  return (
    <Wrapper>
      {size > 0 &&
        [...Array(size)].map((x, i) => (
          <Item
            isActive={activeItems === i}
            onClick={() => onClick(i)}
            key={i}
          />
        ))}
    </Wrapper>
  );
};
