import React from "react";

type CloseProps = {
  onClick: () => void;
};

const CloseIcon: React.FC<CloseProps> = ({ onClick }) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M10 0C4.478 0 0 4.478 0 10c0 5.523 4.478 10 10 10 5.523 0 10-4.477 10-10 0-5.522-4.477-10-10-10zm3.46 14.953l-3.453-3.419L6.576 15l-1.528-1.527 3.42-3.465L5 6.576l1.527-1.528L9.99 8.467 13.412 5l1.54 1.54-3.416 3.452L15 13.412l-1.54 1.54z"
        fill="#fff"
      />
    </svg>
  );
};

export default CloseIcon;
