import React from "react";
import styled from "styled-components";
import { useCarousel } from "../../context/Carousel";

const Wrapper = styled.div<{ hidden: boolean }>`  
  display: inline-block;
  opacity: ${(props) => (props.hidden ? "0" : "1")};    
  
}
`;

const FadeElement: React.FC = ({ children }) => {
  const { offset } = useCarousel();
  const [hidden, setHidden] = React.useState(false);
  const ref = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    if (
      ref?.current &&
      offset > ref?.current?.offsetLeft &&
      offset < ref?.current?.offsetLeft + ref?.current?.offsetWidth
    ) {
      setHidden(true);
    } else {
      setHidden(false);
    }
  }, [offset]);

  return (
    <Wrapper hidden={hidden} ref={ref}>
      {children}
    </Wrapper>
  );
};

export default FadeElement;
