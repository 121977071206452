import React from "react";
import { graphql } from "gatsby";
import { Main } from "../layout/main";
import SliderSection from "../components/SliderSection";
import VideoSection from "../components/VideoSection";
import TestimonialSection from "../components/TestimonialSection";
import ServicesSection from "../components/ServicesSection";
import MapSection from "../components/MapSection";
import { ContentfulHeroImage, HeroImage } from "../types/HeroImage";
import { allContentfulTestimonial, Testimonial } from "../types/Testimonial";
import { allContentfulService, Service } from "../types/Service";
import { contentfulTextualContent } from "../types/TextualContents";
import { allContentfulPinMap, PinMap } from "../types/PinMap";

type SpaProps = {
  data?: {
    allContentfulHeroImage: {
      edges: { node: ContentfulHeroImage }[];
    };
    allContentfulTestimonial: {
      edges: { node: allContentfulTestimonial }[];
    };
    allContentfulService: {
      edges: { node: allContentfulService }[];
    };
    contentfulTextualContent: contentfulTextualContent;
    allContentfulPinMap: {
      edges: { node: allContentfulPinMap }[];
    };
  };
};

const IndexPage: React.FC<SpaProps> = ({ data }) => {
  const heroImages:
    | HeroImage[]
    | undefined = data?.allContentfulHeroImage?.edges?.map((i) => ({
    key: i.node.id,
    title: i.node.title,
    character: i.node?.character?.file?.url,
    background: i?.node?.background?.file?.url,
    content: i.node.content.content,
    tagCoords: i?.node?.tagCoords,
  }));

  const testimonials:
    | Testimonial[]
    | undefined = data?.allContentfulTestimonial?.edges?.map((i) => ({
    key: i.node.id,
    name: i.node.name,
    city: i.node.city,
    country: i.node.country,
    description: i.node?.description?.description,
    image: i.node?.image?.file?.url,
  }));

  const services:
    | Service[]
    | undefined = data?.allContentfulService?.edges?.map((i) => ({
    key: i.node.id,
    name: i.node.name,
    description: i.node?.description?.description,
    image: i.node?.image?.file?.url,
  }));

  const sectionProps = data?.contentfulTextualContent;

  const pinMap: PinMap[] | undefined = data?.allContentfulPinMap?.edges?.map(
    (i) => ({
      key: i.node.id,
      keyCountry: i.node.keyCountry,
      name: i.node.name,
      active: i.node.active,
      city: i.node.city,
      website: i.node.website,
      email: i.node.email,
      image: i.node?.image?.file?.url,
      path: i.node?.path?.path,
      address: i.node?.address?.address,
    })
  );

  return (
    <Main title={sectionProps?.footer?.footer}>
      <SliderSection heroImages={heroImages} />
      <VideoSection
        title={sectionProps?.videoTitle}
        subtitle={sectionProps?.videoSubtitle?.videoSubtitle}
        buttonLabel={sectionProps?.videoCta}
      />
      <TestimonialSection
        testimonials={testimonials}
        title={sectionProps?.testimonialsTitle}
        subtitle={sectionProps?.testimonialsSubtitle?.testimonialsSubtitle}
      />
      <ServicesSection
        services={services}
        title={sectionProps?.servicesTitle}
        subtitle={sectionProps?.servicesSubtitle?.servicesSubtitle}
      />
      <MapSection
        title={sectionProps?.mapTitle}
        subtitle={sectionProps?.mapSubtitle?.mapSubtitle}
        mapPin={pinMap}
      />
    </Main>
  );
};

export default IndexPage;
export const pageQuery = graphql`
  query MainQuery($language: String) {
    allContentfulTestimonial(
      filter: { node_locale: { eq: $language } }
      sort: { fields: sort, order: ASC }
    ) {
      edges {
        node {
          id
          name
          city
          country
          description {
            description
          }
          image {
            file {
              url
            }
          }
        }
      }
    }
    allContentfulHeroImage(
      filter: { node_locale: { eq: $language } }
      sort: { fields: sort, order: ASC }
    ) {
      edges {
        node {
          id
          title
          tagCoords {
            left
            top
          }
          content {
            content
          }
          background {
            file {
              url
            }
          }
          character {
            file {
              url
            }
          }
        }
      }
    }
    allContentfulService(
      filter: { node_locale: { eq: $language } }
      sort: { fields: sort, order: ASC }
    ) {
      edges {
        node {
          id
          name
          description {
            description
          }
          image {
            file {
              url
            }
          }
        }
      }
    }
    contentfulTextualContent(node_locale: { eq: $language }) {
      id
      videoTitle
      videoSubtitle {
        videoSubtitle
      }
      videoCta
      testimonialsTitle
      testimonialsSubtitle {
        testimonialsSubtitle
      }
      servicesTitle
      servicesSubtitle {
        servicesSubtitle
      }
      mapTitle
      mapSubtitle {
        mapSubtitle
      }
      footer {
        footer
      }
      cookieTitle
    }
    allContentfulPinMap(
      filter: { node_locale: { eq: $language } }
      sort: { fields: sort, order: ASC }
    ) {
      edges {
        node {
          id
          keyCountry
          name
          active
          city
          website
          email
          address {
            address
          }
          image {
            file {
              url
            }
          }
          path {
            path
          }
        }
      }
    }
  }
`;
