import React, { useState } from "react";
import styled from "styled-components";
import PreviewImg from "../../images/video_section/preview.jpg";
import PlayImg from "../../images/video_section/play.svg";
import { SubTitle, Title } from "../Typography";
import Button from "../Button";
import Section from "../Section";
import Container from "../Container";
import Modal from "../Modal";
import { useIntl } from "gatsby-plugin-intl";
import { CodeColors } from "../../types/CodeColors";
import { TextualContentProps } from "../../types/TextualContents";
import { SectionPros } from "../../types/SectionsProps";

const Wrapper = styled.div`
  display: flex;
  min-height: 600px;
  height: 70vh;
  padding: 20px 0;
  @media (max-width: 1024px) {
    flex-direction: column;
    height: 100%;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ContentWrapper = styled.div`
  width: 50%;
  margin-left: 20px;
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 1024px) {
    margin-left: 0;
    width: 100%;
  }
`;

const ButtonContent = styled.div`
  padding-top: 35px;
`;

const Preview = styled.img`
  width: 100%;
  border-radius: 20px;
`;

const Play = styled.img`
  position: absolute;
`;

type CTAProps = {
  buttonLabel: string;
};

const VideoSection: React.FC<SectionPros & CTAProps> = ({
  title,
  subtitle,
  buttonLabel,
}) => {
  const [open, setOpen] = useState(false);
  const intl = useIntl();

  function changeModalState() {
    setOpen(!open);
  }
  return (
    <Section
      fullHeight={false}
      height="100%"
      id="why-step"
      background={CodeColors.white}
    >
      <Container>
        <Wrapper>
          <ImageWrapper>
            <Preview alt="Video preview" src={PreviewImg} />
            <Play alt="Play" src={PlayImg} onClick={changeModalState} />
          </ImageWrapper>
          <ContentWrapper>
            <Title color={CodeColors.cyan}>{title}</Title>
            <SubTitle> {subtitle} </SubTitle>
            <ButtonContent>
              <Button onClick={changeModalState}>{buttonLabel}</Button>
            </ButtonContent>
          </ContentWrapper>
        </Wrapper>
      </Container>
      {open && <Modal onClose={changeModalState} />}
    </Section>
  );
};

export default VideoSection;
