import * as React from "react";
import styled from "styled-components";
import World from "../../images/map/world.svg";
import Email from "../../images/map/email.svg";
import Address from "../../images/map/pin.svg";
import CloseIcon from "./CloseIcon";
import { useIntl } from "gatsby-plugin-intl";
import { CodeColors } from "../../types/CodeColors";
import { PinMap } from "../../types/PinMap";

type ImageWrapperProps = {
  background?: string | null;
};

type CardProps = {
  coords: number[];
  selectedCountry?: PinMap;
  close: () => void;
};

const Wrapper = styled.div<{ top: number; left: number }>`
  top: ${(props) => (props.top ? props.top - 280 : "")}px;
  left: ${(props) => (props.top ? props.left - 30 : "")}px;
  width: 260px;
  white-space: normal;
  margin-right: 0;
  position: absolute;
  right: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    width: 220px;
    white-space: normal;
  }
`;

const ImageWrapper = styled.div<ImageWrapperProps>`
  border-radius: 20px 20px 0 0;
  height: 240px;
  width: 100%;
  background-image: url("${(props) =>
    props.background ? props.background : ""}");
  background-position: top;
  background-repeat: no-repeat;
  @media (max-width: 768px) {
    height: 220px;
  }
`;

const BodyWrapper = styled.div<ImageWrapperProps>`
  border-radius: 0 0 20px 20px;
  width: 100%;
  background: ${CodeColors.white};
`;

const CardTitle = styled.h5`
  font-size: 30px;
  text-align: center;
  color: ${CodeColors.darkBlue};
  margin: auto;
  padding-top: 10px;
`;

const CardSubTitle = styled.h6`
  font-size: 10px;
  text-align: center;
  text-transform: uppercase;
  color: ${CodeColors.lightBlue};
  margin: 0;
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

const WorldIcon = styled.img``;

const EmailIcon = styled.img``;

const DivInfo = styled.div`
  padding: 20px 40px;
  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const DivParagraph = styled.div`
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: start;
  i,
  a {
    text-decoration: none;
    font-style: italic;
    font-size: 10px;
    width: 70%;
    text-align: center;
  }
`;

const Card: React.FC<CardProps> = ({ coords, selectedCountry, close }) => {
  const intl = useIntl();

  return (
    <Wrapper top={coords && coords[0]} left={coords && coords[1]}>
      <IconWrapper>
        <CloseIcon onClick={close} />
      </IconWrapper>
      <ImageWrapper
        background={selectedCountry ? selectedCountry?.image : ""}
      />
      <BodyWrapper>
        <CardTitle>{selectedCountry?.city}</CardTitle>
        <CardSubTitle>headquarter - {selectedCountry?.name}</CardSubTitle>
        <DivInfo>
          <DivParagraph>
            <WorldIcon src={Address} alt={selectedCountry?.address || ""} />
            <i>{selectedCountry?.address}</i>
          </DivParagraph>
          <DivParagraph>
            <WorldIcon src={World} alt={selectedCountry?.website || ""} />
            <a rel="noreferrer" target="_blank" href={selectedCountry?.website}>
              <i>{selectedCountry?.website}</i>
            </a>
          </DivParagraph>
          <DivParagraph>
            <EmailIcon src={Email} alt={selectedCountry?.email || ""} />
            <a href={`mailto:${selectedCountry?.email}`}>
              <i>{selectedCountry?.email}</i>
            </a>
          </DivParagraph>
        </DivInfo>
      </BodyWrapper>
    </Wrapper>
  );
};

export default Card;
