import React from "react";
import styled from "styled-components";
import ReactPlayer from "react-player/lazy";
import { CodeColors } from "../../types/CodeColors";

type ModalProps = {
  onClose?: () => void;
};

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${CodeColors.dark};
`;

const VideoContainer = styled.div`
  margin: auto;
`;

const VideoWrapper = styled.div`
  width: 100vw;
  height: calc(100vh - 75px);
  margin-top: 75px;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 25px;
  right: 50px;
  color: ${CodeColors.white};
  font-size: 20px;
  cursor: pointer;
`;

const ModalSection: React.FC<ModalProps> = ({ onClose }) => {
  return (
    <Wrapper>
      <VideoContainer>
        <VideoWrapper>
          <ReactPlayer
            width="100vw"
            height="calc(100vh - 75px)"
            controls={true}
            url="https://youtu.be/ctHqWEPuDXw"
            playing={true}
          />
        </VideoWrapper>
        <CloseButton onClick={onClose && onClose}>X</CloseButton>
      </VideoContainer>
    </Wrapper>
  );
};

export default ModalSection;
