import React from "react";
import { MapSectionProps } from "../../types/mapSectionProps";
import styled from "styled-components";
import Pin from "./Pin";
import { useIntl } from "gatsby-plugin-intl";
import { CodeColors } from "../../types/CodeColors";

type CountryProps = {
  active: boolean;
};

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const CountryWrapper = styled.div<CountryProps>`
  display: flex;
  margin: 5px 0;
  align-items: center;
  cursor: ${(props) => (props.active ? "pointer" : "not-allowed")};
`;
const Label = styled.span`
  color: ${CodeColors.white};
  margin-left: 10px;
`;

export const MapLegend: React.FC<MapSectionProps> = ({
  countries,
  toggleColor,
  toggleTooltip,
  handleSelection,
}) => {
  const intl = useIntl();

  return (
    <Wrapper>
      {countries.map((c) => (
        <CountryWrapper
          onMouseOver={(e) =>
            toggleTooltip(e, c.active ? c.name : "Coming soon")
          }
          onMouseOut={(e) => toggleTooltip(e)}
          key={c.key}
          onClick={() => {
            c.active && handleSelection && handleSelection(c.key);
          }}
          active={c.active}
        >
          <Pin color={toggleColor(c)} />
          <Label>{c.name}</Label>
        </CountryWrapper>
      ))}
    </Wrapper>
  );
};
