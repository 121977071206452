import React from "react";
import Section from "../Section";
import Slider from "../Slider";
import { SliderProps } from "../../types/HeroImage";
import { CodeColors } from "../../types/CodeColors";

const SliderSection: React.FC<SliderProps> = ({ heroImages }) => {
  return (
    <Section fullHeight={false} id="about-us" background={CodeColors.white}>
      <Slider heroImages={heroImages} />
    </Section>
  );
};

export default SliderSection;
