import React from "react";
import styled, { keyframes } from "styled-components";
import Button from "../Button";
import mobileBg from "../../images/slider/mobileBg.svg";
import Tag from "./Tag";
import { SlideProps } from "../../types/HeroImage";
import { CutElement } from "./CutElement";
import { CodeColors } from "../../types/CodeColors";

const titleAnimation = keyframes`
    0% {      
      transform: translateY(-10px);
      opacity:0;
    }
    100% {    
      transform: translateY(0px);
      opacity:1;
    }
`;

const subTitleAnimation = keyframes`
  0% {
    transform: translateY(10px);
    opacity:0;
  }
  100% {
    transform: translateY(0px);
    opacity:1;
  }
`;

const Wrapper = styled.div<{ active: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  height: 100%;
  margin: 0 auto;
  visibility: ${(props) => (props.active ? "visible" : "hidden")};
`;

const Background = styled.div<{ background?: string; active: boolean }>`
  background-image: url(${(props) =>
    props.background ? props.background : ""});
  transform: translateX(100%);
  animation: ${(props) => (props.active ? "slide-in" : "slide-out")} 0.5s
    forwards;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50%;
  @media (min-width: 1440px) {
    width: 50%;
    background-size: cover;
  }
  @media (max-width: 1024px) {
    width: 100%;
    background-position: center;
  }
`;

const RightSide = styled.div`
  position: relative;
  width: 50%;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const LeftSide = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 1024px) {
    background-image: url("${mobileBg}");
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: transparent;
    justify-content: flex-start;
    padding-left: 20px;
  }
`;

const LeftContentWrapper = styled.div<{ character?: string; active: boolean }>`
  background-image: url("${(props) =>
    props.character ? props.character : ""}");
  opacity: ${(props) => (props.active ? 1 : 0)};
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 600px;
  padding: 0;
  @media (max-width: 1200px) {
    padding-left: 20px;
  }
  @media (max-width: 1024px) {
    background: none;
    width: 70%;
    padding-left: 0;
  }
  button {
    max-width: 200px;
  }
`;

const RightContentWrapper = styled.div`
  position: relative;
  width: 600px;
  @media (min-width: 1900px) {
    width: 100%;
  }
`;

const TextWrapper = styled.div`
  padding-right: 10px;
`;

const ButtonWrapper = styled.div<{ active: boolean }>`
  display: inline-flex;
  opacity: 0;
  animation: ${(props) => props.active && subTitleAnimation} 1s forwards;
  animation-delay: 0.8s;
`;

const Title = styled.h2<{ active: boolean }>`
  color: ${CodeColors.white};
  font-size: 42px;
  font-weight: 700;
  margin: 10px 0;
  padding-right: 10px;
  opacity: 0;
  animation: ${(props) => props.active && titleAnimation} 1s forwards;
  transform: translate3d(0px, 20px, 0x);
`;

const SubTitle = styled.h3<{ active: boolean }>`
  color: ${CodeColors.white};
  font-size: 32px;
  line-height: 36px;
  font-weight: 400;
  margin: 10px 0 40px;
  opacity: 0;
  animation: ${(props) => props.active && subTitleAnimation} 1s forwards;
  animation-delay: 0.5s;
  padding-right: 10px;
  @media (max-width: 1024px) {
    font-size: 26px;
    line-height: 32px;
  }
`;

const Slide: React.FC<SlideProps> = ({
  title,
  subTitle,
  callToAction,
  background,
  character,
  index,
  isActive,
  tags,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <Wrapper active={isActive}>
      <Background background={background} active={isActive} />
      <LeftSide>
        <LeftContentWrapper active={isActive} character={character}>
          <TextWrapper>
            <Title active={isActive}> {title} </Title>
            <SubTitle active={isActive}>{subTitle} </SubTitle>
          </TextWrapper>
          <ButtonWrapper active={isActive}>
            <Button type={1} href="#why-step">
              {callToAction}
            </Button>
          </ButtonWrapper>
        </LeftContentWrapper>
      </LeftSide>
      <RightSide>
        <CutElement />
        <RightContentWrapper>
          {tags.map((t, i) => (
            <Tag
              index={i}
              key={`tag.${index}.${i}`}
              top={t.top}
              left={t.left}
              active={t.active}
              label={t.label}
              slide={t.slide}
              visible={isActive}
              onClick={onClick}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            />
          ))}
        </RightContentWrapper>
      </RightSide>
    </Wrapper>
  );
};

export default Slide;
