import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import Slide from "./Slide";
import { Navigation } from "./Navigation";
import { useIntl } from "gatsby-plugin-intl";
import { SliderProps } from "../../types/HeroImage";
import { CodeColors } from "../../types/CodeColors";

const Wrapper = styled.div`
  min-height: 570px;
  height: 570px;
  width: 100%;
  background: ${CodeColors.darkBlue};
  position: relative;
  overflow: hidden;
`;

const SliderWrapper = styled.div<{ current: number }>`
  margin: 0 auto;
  height: 100%;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const Slider: React.FC<SliderProps> = ({ heroImages }) => {
  const [selectedSlide, setSelectedSlide] = React.useState(0);
  const [loop, setLoop] = React.useState<NodeJS.Timeout>();
  const intl = useIntl();
  const [touchStart, setTouchStart] = React.useState(0);
  const [touchEnd, setTouchEnd] = React.useState(0);

  function handleTouchStart(e: React.TouchEvent<HTMLDivElement>) {
    setTouchStart(e.targetTouches[0].clientX);
  }

  function handleTouchMove(e: React.TouchEvent<HTMLDivElement>) {
    setTouchEnd(e.targetTouches[0].clientX);
  }

  function handleTouchEnd() {
    if (touchStart - touchEnd > 100) {
      setSelectedSlide((selectedSlide) =>
        heroImages && selectedSlide < heroImages.length - 1
          ? selectedSlide + 1
          : 0
      );
    }

    if (touchStart - touchEnd < -100) {
      setSelectedSlide((selectedSlide) =>
        heroImages && selectedSlide > 0
          ? selectedSlide - 1
          : heroImages
          ? heroImages.length - 1
          : 0
      );
    }

    resetInterval();
    initInterval();
  }

  const initInterval = () => {
    const t = setInterval(() => {
      setSelectedSlide((selectedSlide) =>
        heroImages && selectedSlide < heroImages.length - 1
          ? selectedSlide + 1
          : 0
      );
    }, 5000);

    setLoop(t);
    return t;
  };

  const resetInterval = useCallback(() => {
    loop && clearInterval(loop);
  }, [loop]);

  const clickHandler = (slide: number) => {
    setSelectedSlide(slide);
    resetInterval();
    initInterval();
  };

  useEffect(() => {
    const t = initInterval();
    return clearInterval(t);
  }, []);

  return (
    <Wrapper>
      <SliderWrapper
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        current={selectedSlide}
      >
        {heroImages &&
          heroImages.map((i, k) => (
            <Slide
              onMouseEnter={resetInterval}
              onMouseLeave={() => {
                resetInterval();
                initInterval();
              }}
              key={i.key}
              title={i.title}
              background={i.background}
              character={i.character}
              subTitle={i.content}
              callToAction={intl.formatMessage({ id: "about-us.cta" })}
              isActive={k === selectedSlide}
              index={k}
              tags={heroImages.map((_i, _k) => ({
                label: _i.title,
                active: _k === selectedSlide,
                top: _i?.tagCoords?.top,
                left: _i?.tagCoords?.left,
                slide: _k,
              }))}
              onClick={clickHandler}
            />
          ))}
      </SliderWrapper>
      <Navigation
        activeItems={selectedSlide}
        onClick={clickHandler}
        size={heroImages ? heroImages.length : 0}
      />
    </Wrapper>
  );
};

export default Slider;
