import React from "react";
import styled from "styled-components";
import Card from "../Card";
import { Paragraph, SubTitle, Title } from "../Typography";
import Container from "../Container";
import Section from "../Section";
import { useIntl } from "gatsby-plugin-intl";
import Cut from "../../images/people/cut.svg";
import { Scrollable } from "../Scrollable/Scrollable";
import FadeElement from "../Scrollable/FadeElement";
import { TestimonialsProps } from "../../types/Testimonial";
import { CodeColors } from "../../types/CodeColors";
import { SectionPros } from "../../types/SectionsProps";

const CutWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-image: url("${Cut}");
  background-repeat: no-repeat;
  display: none;
  width: 160px;
  @media (min-width: 1400px) {
    display: block;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardContent = styled.div`
  padding: 20px;
  text-align: center;
  p {
    height: 130px;
  }
`;

const CardTitle = styled.h5`
  font-size: 22px;
  text-align: center;
  color: #16455f;
  margin-bottom: 15px;
`;

const CardSubTitle = styled.h6`
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #58dede;
  margin: 0;
`;

const TestimonialSection: React.FC<TestimonialsProps & SectionPros> = ({
  testimonials,
  title,
  subtitle,
}) => {
  const intl = useIntl();

  return (
    <Section id="testimonials" background={CodeColors.darkBlue}>
      <CutWrapper />
      <ContentWrapper>
        <Container>
          <Title color={CodeColors.cyan}> {title} </Title>
          <SubTitle color={CodeColors.white}> {subtitle} </SubTitle>
        </Container>
      </ContentWrapper>
      <Scrollable>
        {testimonials &&
          testimonials.map((item) => (
            <FadeElement key={item.key}>
              <Card image={item.image}>
                <CardContent>
                  <Paragraph>
                    <i>{item.description}</i>
                  </Paragraph>
                  <CardTitle>{item.name}</CardTitle>
                  <CardSubTitle>
                    {item.city} - {item.country}
                  </CardSubTitle>
                </CardContent>
              </Card>
            </FadeElement>
          ))}
      </Scrollable>
    </Section>
  );
};

export default TestimonialSection;
